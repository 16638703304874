<template>
  <HomeMobile v-if="isMobile" />
  <HomePC v-if="!isMobile" />
</template>

<script>
import HomePC from "./HomePC.vue";
import HomeMobile from "./HomeMobile.vue";

export default {
  name: "Home",
  components: {
    HomePC,
    HomeMobile,
  },

  data() {
    return {
      isMobile: true,
    };
  },
  created() {
    let that = this;
    let browser = {
      versions: (function () {
        var u = navigator.userAgent;
        return {
          //移动终端浏览器版本信息
          trident: u.indexOf("Trident") > -1, //IE内核
          presto: u.indexOf("Presto") > -1, //opera内核
          webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
          gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
          iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf("iPad") > -1, //是否iPad
          webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
        };
      })(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase(),
    };
    if (
      browser.versions.mobile == true ||
      browser.versions.ios == true ||
      browser.versions.android == true
    ) {
      that.isMobile = true;
    } else {
      that.isMobile = false;
    }
  },
};
</script>

<style>
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  /*去掉移动端触摸高亮bgu */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
